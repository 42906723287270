import { defineStore } from "pinia";
import ProjectService from "../services/project-service";

export const useProjectStore = defineStore("projectStore", {
    state: () => ({
        items: [],
        searchQuery: {
			q: null,
		},
        activeProject: {},
        searchResults: [],
    }),
    getters: {
        getProject() {
            return this.activeProject
        },
        getProjects: (state) => {
            return (uic) => state.authorDepartments[uic]
        }
    },
    actions: {
        clearList() {
            this.projects = [];
        },
        async fetch(params, loadingCallBack ) {
            let service = new ProjectService();
            const results = await service.fetchAll(params, loadingCallBack);
            this.items = results.data.data;
        },
        async search(params, loadingCallBack ) {
            let service = new ProjectService();
            const results = await service.search(params, loadingCallBack);
            this.items = results.data.data;
        },
    }
})
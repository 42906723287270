import { axiosInst } from "../main";
import BaseService from "./base-service";

export default class BulkService extends BaseService {
    
    async fetchAll( id, colid, params, loadingCallback )  {
        let path = (id, colid) => `/projects/${id}/collections/${colid}/bulks`
        const response = await axiosInst.get( path(id, colid), {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async get( id, cid, params, loadingCallback )  {
        let path = (id, cid) => `/public/projects/${id}/collections/${cid}`
        const response = await axiosInst.get( path(id, cid), {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }
}
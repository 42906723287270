import Vue from 'vue'
import VueRouter from 'vue-router'
import ProjectsList from '../components/ProjectsList.vue'
import Project from '../components/Project.vue'
import Collection from '../components/Collection.vue'
import ProjectManager from '../components/ProjectManager.vue'
import CollectionManager from '../components/CollectionManager.vue'
import BulkUpload from '../components/BulkUpload.vue'

import {authStore} from '../stores/user.js'
//import {APP} from '../main'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'projects',
    component: ProjectsList,
  },
  {
    path: '/projects/:project',
    name: 'project',
    component: Project,
    props: (route) => ({projectId: route.params.project})
  },
  {
    path: '/projects/:project/collections/:collection',
    name: 'collection',
    component: Collection,
    props: (route) => ({projectId: route.params.project, collectionId: route.params.collection})
  },
  {
    path: '/manager/projects/:project',
    name: 'manager-project',
    component: ProjectManager,
    props: (route) => ({projectId: route.params.project})
  },
  {
    path: '/manager/projects/:project/collections/:collection',
    name: 'manager-collection',
    component: CollectionManager,
    props: (route) => ({projectId: route.params.project, collectionId: route.params.collection})
  },
  {
    path: '/manager/projects/:project/collections/:collection/upload',
    name: 'manager-bulk-upload',
    component: BulkUpload,
    props: (route) => ({projectId: route.params.project, collectionId: route.params.collection})
  },
  /*
  {
    path: '/publications/new/:type',
    name: 'create-new-publication',
    component: PublicationForm,
    props: (route) => ({isNewEntry: true, isImporting: route.params.isImporting, type: route.params.type})
  },
  {
    path: '/publications/:id',
    name: 'edit-publication',
    component: PublicationForm,
    props: (route) => ({isNewEntry: false, isImporting: route.params.isImporting, editEntry: route.params.id})
  },
  // import
  {
    path: '/publication/import',
    name: 'import-publications',
    component: ImportPublications
  },
  {
    path: '/publication/import/:id',
    name: 'import-publication',
    component: ImportPublication,
    props: (route) => ({isNewEntry: true, isImporting: true, importEntry: route.params.id})
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
  },
  */
 

  /*
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ /*'../views/About.vue')
  }
  */
]


const router = new VueRouter({
  routes
})

router.beforeEach( (to, from, next) => {
 // const userStore = authStore()

  //userStore.verify()
  next()
})

export default router

<template>
	
	<div class="container">
		<router-link :to="{name: 'project', params: {project: collection.projectId}}" class="btn btn-primary btn-xs">&laquo; Back to project</router-link>

		<div class="row align-items-stretch g-4 py-3">
			<div class="col-12">
				<div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" :style="{'background-image': 'url(\'' + collection.image + '\')'}">
					<div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
						<h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold" style="text-shadow: 2px 2px 4px #000;">{{collection.name}}</h3>
						<ul class="d-flex list-unstyled mt-auto">
						</ul>
					</div>
				</div>
      		</div>
			<div class="row mt-5">
				<div class="col-8 mt-3">
					<div v-html="collection.description"></div>
					<PackageView :collectionId="collectionId" :projectId="projectId"></PackageView>
				</div>
				<div class="col-4">
					<div style="text-align: center;" class="mt-3 mb-3">
						<a :href="collection.downloadLink" class="btn btn-block btn-success" target="_blank">Download/Show data</a>
					</div>
					<ol class="list-group">
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Project</div>
								{{ collection.project }}
							</div>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Collection type</div>
								{{ collection.type }}
							</div>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Availability</div>
								{{ collection.availability }}
							</div>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Number of Records</div>
								{{ collection.dataCount }}
							</div>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Size (B)</div>
								{{ collection.dataSize }}
							</div>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Author(s)</div>
								{{ attributes.authors }}
							</div>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Keywords</div>
								{{ attributes.keywords }}
							</div>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">DOI</div>
								<a :href="'//doi.org/' + attributes.doi" target="_blank">{{ attributes.doi }}</a>
							</div>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Cite</div>
								{{ attributes.cite }}
							</div>
						</li>
					</ol>
					<ol class="list-group mt-3">
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Administrator</div>
								{{ collection.contact }}<br />
								{{ collection.contactEmail }}
							</div>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Rights</div>
								<img src="assets/liccc.png" />
								<p>
									<a href="https://creativecommons.org/licenses/by/4.0/legalcode" target="_blank">Creative Commons Attribution 4.0 International</a>
								</p>
								
							</div>
						</li>
					</ol>
					<div>
						<br />
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script> 
import { useProjectStore } from '../stores/project-store'
import { useCollectionStore } from '../stores/collection-store'
import PackageView from './PackageView.vue';

export default {
	components: {PackageView},
	name: 'Collection',
	props: {
		projectId: [String,Number],
		collectionId: [String,Number],
	},
	data: () => ({
		search: '',
		projects: useProjectStore(),
		collections: useCollectionStore(),
		project: {},
		collection: {},
		attributes: {
			authors: "",
			doi: "",
			keywords: "",
			cite: "",
		}
	}),
	mounted: async function() {
		

		await this.load()
	},
	methods: {
		load: async function() {

			if(this.collections && this.collections.items && this.collections.items.length == 0) {
				await this.collections.fetch(this.projectId)
			}

			await this.collections.get(this.projectId, this.collectionId).then( r => {
				this.collection = r.data

				console.log(this.collection)

				this.$nextTick( this.prepAttributes );

			 })

			//this.collection = this.collections.items.find(f => f.id == this.collectionId)
		},
		prepAttributes: function() {
			if(this.collection.attributes && this.collection.attributes.length > 0) {
						this.attributes.doi = this.getAttribute(1).value;
						this.attributes.authors = this.getAttribute(2).value;
						this.attributes.cite = this.getAttribute(3).value;
						this.attributes.keywords = this.getAttribute(4).value;
						console.log("checking attrs", this.attributes)
					}	
		},
		getAttribute: function(id) {
			let attr = this.collection.attributes.find( f => f.attributeId == id)
			if(!attr) {
				attr = {value: "--"}
			}

			return attr
		}
	}
}

</script>
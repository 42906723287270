import { axiosInst } from "../main";
import BaseService from "./base-service";

export default class CollectionService extends BaseService {
    
    async fetchAll( id, params, loadingCallback )  {
        let path = (id) => `/public/projects/${id}/collections`
        const response = await axiosInst.get( path(id), {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async get( id, cid, params, loadingCallback )  {
        let path = (id, cid) => `/public/projects/${id}/collections/${cid}`
        const response = await axiosInst.get( path(id, cid), {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async search( params, loadingCallback )  {
        const response = await axiosInst.get('/public/projects/-/collections', {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async getCollectionListing( id, params ) {
        const response = await axiosInst(`https://collector.datahive.czu.cz/collections/${id}`, {
            params
        })

        return response;
    }
    
}
// https://github.com/SortableJS/Vue.Draggable?ref=madewithvuejs.com
import Vue from 'vue'
import App from './App.vue'
//import PublicationList from './components/PublicationList.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import router from './router'
import pinia from './plugins/pinia'
import i18n from './i18n'

Vue.config.productionTip = false

export const axiosInst = axios.create({
	//baseURL: "http://localhost:8089/api/v1/",
	baseURL: "https://api.datahive.czu.cz/api/v1/",
	headers: {
		"Content-Type" : "application/json"
	}
});

new Vue({
    vuetify,
    router,
    pinia,

    data: {
		currentUIC: 123,
		effectiveUIC: 123,
		applicationLoading: false,
	},

    emits: ['application-loading'],

    created: function() {
		axiosInst.interceptors.request.use( request => {
			console.log("axios loading");
			this.applicationLoading = true
			this.$emit("applicationLoading", true)
			return request;
		}, error => {
			console.log(error)
		});

		axiosInst.interceptors.response.use( response => {
			this.applicationLoading = false
			console.log("axios loaded");
			setTimeout( () => {this.$emit("applicationLoading", false)}, 1000)
			return response;
		}, error => {
//			console.log("axios err", error)
			this.applicationLoading = false
			console.log("axios - error detected", error.response.status)
			setTimeout( () => {this.$emit("applicationLoading", false)}, 1000)
			return Promise.reject(error)
		})
	},

    methods: {
		getCurrentUser: function() {
			return this.currentUIC
		},
		getEffectiveUser: function() {
			return this.effectiveUIC
		}
	},

    i18n,
    render: h => h(App)
})
.$mount('#app')


<template>
    <div>
      <router-view></router-view>
    </div>
</template>

<script>
import AlertManager from './components/AlertManager.vue';
//import PublicationList from './components/PublicationList.vue'

export default {
  components: { AlertManager },
  name: 'App',

  data: () => ({
    currentUIC: Number,
    applicationLoading: false,
    currentUser: Object,
    menuOpened: false, 
    tenants: ["Testovací tenant"]
  }),

  methods: {
    applicationLoadingEvent(state) {
      console.log("loading event captured")
      this.applicationLoading = state
    },
  },

  mounted: function() {
    this.applicationLoading = false
    this.currentUIC = 67400
    this.$root.$on("applicationLoading", this.applicationLoadingEvent )
  },
};
</script>
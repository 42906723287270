import { axiosInst } from "../main";
import BaseService from "./base-service";

export default class ProjectService extends BaseService {
    
    async fetchAll( params, loadingCallback )  {
        const response = await axiosInst.get('/public/projects', {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async search( params, loadingCallback )  {
        const response = await axiosInst.get('/public/projects', {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }
    
}
<template>
	
	<div class="container">
		<router-link :to="{name: 'manager-project', params: {project: collection.projectId}}" class="btn btn-primary btn-xs">&laquo; Back to project management </router-link>
		&nbsp; 
		<router-link :to="{name: 'manager-collection', params: {collection: collection.id, project: collection.projectId}}" class="btn btn-primary btn-xs">&laquo; Back to collection management </router-link>

		<div class="row align-items-stretch g-4 py-3">
			<div class="col-12">
				<div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" :style="{'background-image': 'url(\'' + collection.image + '\')'}">
					<div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
						<h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold" style="text-shadow: 2px 2px 4px #000;">{{collection.name}}</h3>
						<ul class="d-flex list-unstyled mt-auto">
						</ul>
					</div>
				</div>
      		</div>
			<div>
				<vue2Dropzone ref="dropzone" id="dropzone" :options="dropzoneOptions" :duplicateCheck="true"  v-on:vdropzone-sending="sendingEvent"></vue2Dropzone>
			</div>
		</div>
	</div>

</template>

<script> 
import { useProjectStore } from '../stores/project-store'
import { useCollectionStore } from '../stores/collection-store'
import PackageView from './PackageView.vue';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
	components: {PackageView, vue2Dropzone},
	name: 'BulkUpload',
	props: {
		projectId: [String,Number],
		collectionId: [String,Number],
	},
	data: () => ({
		search: '',
		projects: useProjectStore(),
		collections: useCollectionStore(),
		project: {},
		collection: {},
		attributes: {
			authors: "",
			doi: "",
			keywords: "",
			cite: "",
		},
		dropzoneOptions: {
			url: 'https://',
			thumbnailWidth: 150,
			maxFilesize: 20000000,
			withCredentials: false,
		}
	}),
	mounted: async function() {
		await this.load()
	},
	methods: {
		load: async function() {

			if(this.collections && this.collections.items && this.collections.items.length == 0) {
				await this.collections.fetch(this.projectId)
			}

			await this.collections.get(this.projectId, this.collectionId).then( r => {
				this.collection = r.data

				console.log(this.collection)

				this.$nextTick( this.prepAttributes );

			})

			this.$refs.dropzone.setOption("url", "https://api.datahive.czu.cz/api/v1/projects/" + this.collection.projectId + "/collections/" + this.collection.id + "/bulk");

			//this.collection = this.collections.items.find(f => f.id == this.collectionId)
		},
		sendingEvent: function(file, xhr, formData) {
			console.log(xhr);
			formData.append('template', "template");
		},
		prepAttributes: function() {
			if(this.collection.attributes && this.collection.attributes.length > 0) {
						this.attributes.doi = this.getAttribute(1).value;
						this.attributes.authors = this.getAttribute(2).value;
						this.attributes.cite = this.getAttribute(3).value;
						this.attributes.keywords = this.getAttribute(4).value;
						console.log("checking attrs", this.attributes)
					}	
		},
		getAttribute: function(id) {
			let attr = this.collection.attributes.find( f => f.attributeId == id)
			if(!attr) {
				attr = {value: "--"}
			}

			return attr
		}
	}
}

</script>
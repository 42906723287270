import { defineStore } from "pinia";
import CollectionService from "../services/collection-service";

export const useCollectionStore = defineStore("collectionStore", {
    state: () => ({
        items: [],
        searchQuery: {
			q: null,
		},
        searchResults: [],
    }),
    getters: {
    },
    actions: {
        clearList() {
            this.projects = [];
        },
        async fetch(id, params, loadingCallBack ) {
            let service = new CollectionService();
            const results = await service.fetchAll(id, params, loadingCallBack);
            this.items = results.data.data;
        },
        async get(id, cid, params, loadingCallBack ) {
            let service = new CollectionService();
            const results = await service.get(id, cid, params, loadingCallBack);
            return results;
        },
        async search(params, loadingCallBack ) {
            let service = new CollectionService();
            const results = await service.search(params, loadingCallBack);
            this.searchResults = results.data.data;
        },
    }
})
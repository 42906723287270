<template>
	
	<div class="container">
		<div class="row align-items-stretch g-4 py-3">
			<div class="col-12">
				<div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" :style="{'background-image': 'url(\'' + project.image + '\')'}">
					<div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
						<h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold" style="text-shadow: 2px 2px 4px #000;">{{project.name}}</h3>
						<ul class="d-flex list-unstyled mt-auto">
						</ul>
					</div>
				</div>
      		</div>
			
			<div>
				<div class="alert alert-info" v-if="project.syncActive">Project editing is limited because this project is synchronized from the external system.</div>
				<form>
					<div class="row mb-3">
						<label class="col-sm-2 col-form-label">Project name</label>
						<div class="col-sm-10">
							<input type="text" :disabled="project.syncActive" class="form-control" v-model="project.name" >
						</div>
					</div>
					<div class="row mb-3">
						<label class="col-sm-2 col-form-label">State</label>
						<div class="col-sm-10">
							<input type="text" disabled class="form-control" v-model="project.state" >
						</div>
					</div>
					<div class="row mb-3">
						<label class="col-sm-2 col-form-label">Source count</label>
						<div class="col-sm-10">
							<input type="text" disabled class="form-control" v-model="project.sourceCount" >
						</div>
					</div>
					<div class="row mb-3">
						<label class="col-sm-2 col-form-label">Project desc</label>
						<div class="col-sm-10">
							<textarea class="form-control" :disabled="project.syncActive" v-model="project.description"></textarea>
						</div>
					</div>
					<div class="row mb-3">
						<label class="col-sm-2 col-form-label">Catalog description</label>
						<div class="col-sm-10">
							<textarea class="form-control" :disabled="project.syncActive" v-model="project.publicDescription"></textarea>
						</div>
					</div>
					<div class="row mb-3">
						<label class="col-sm-2 col-form-label">Project image - large</label>
						<div class="col-sm-10">
							<input type="file" class="form-control" :disabled="project.syncActive">
						</div>
					</div>
					<div class="row mb-3">
						<label class="col-sm-2 col-form-label">Project image - small</label>
						<div class="col-sm-10">
							<input type="file" class="form-control" :disabled="project.syncActive">
						</div>
					</div>
					<div class="row">
						<button :disabled="project.syncActive" class="btn btn-primary">Update project</button>
					</div>
				</form>
			</div>

		</div>

		
		<h3 class="mt-2">Available data collections</h3>
		<div class="alert alert-info" v-if="collections.items.length == 0">There are no collections in this project.</div>
		<div class="alert alert-info" v-if="project.syncActive">This project has synchronized data collections.</div>

		<div class="row justify-items-center g-4 py-1" v-if="collections.items.length > 0" v-for="collection in collections.items" :key="collection.id">
			<div class="col-1">
				<img :src="collection.image" class="img-thumbnail ratio-16x9" width="50" >
			</div>
			<div class="col-6">
				{{ collection.name }}
			</div>
			<div class="col-2">
				<i v-if="collection.availabilityId == 1" class="bi bi-lock-fill" style="font-size: 2rem"></i>
				<i v-if="collection.availabilityId == 2" class="bi bi-database-check" style="font-size: 2rem"></i>
				<i v-if="collection.availabilityId == 3" class="bi bi-bucket-fill" style="font-size: 2rem"></i>
			</div>
			<div class="col-2">
				<router-link :to="{name: 'manager-collection', params: {project: project.id, collection: collection.id}}" class="btn btn-primary btn-xs">Detail</router-link>
				&nbsp;
				<router-link :to="{name: 'manager-bulk-upload', params: {collection: collection.id}}" class="btn btn-primary btn-xs">Upload</router-link>
			</div>
		</div>
	</div>

</template>

<script> 
import { useProjectStore } from '../stores/project-store'
import { useCollectionStore } from '../stores/collection-store'

export default {
	components: {},
	name: 'ProjectManager',
	props: {
		projectId: [String,Number],
	},
	data: () => ({
		search: '',
		projects: useProjectStore(),
		collections: useCollectionStore(),
		project: {}
	}),
	mounted: async function() {
		

		await this.load()
	},
	methods: {
		load: async function() {

			if(this.projects && this.projects.items && this.projects.items.length == 0) {
				await this.projects.fetch()
			}

			this.project = this.projects.items.find(f => f.id == this.projectId)

			await this.collections.fetch(this.projectId);

			console.log(this.project );
		},
	}
}

</script>
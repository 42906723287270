import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);


const opts = {
    /*
    lang: {
        locales: {cs},
        current: 'cs'
    }
    */
};

export default new Vuetify(opts);

<template>
	
	<div class="container">
		<router-link :to="{name: 'manager-project', params: {project: collection.projectId}}" class="btn btn-primary btn-xs">&laquo; Back to project management </router-link>

		<div class="row align-items-stretch g-4 py-3">
			<div class="col-12">
				<div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" :style="{'background-image': 'url(\'' + collection.image + '\')'}">
					<div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
						<h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold" style="text-shadow: 2px 2px 4px #000;">{{collection.name}}</h3>
						<ul class="d-flex list-unstyled mt-auto">
						</ul>
					</div>
				</div>
      		</div>
			
			<div>
				<div class="alert alert-info" v-if="project.syncActive">Collection editing is limited because this project is synchronized from the external system.</div>
				<form>
					<div class="row mb-3">
						<label class="col-sm-2 col-form-label">Collection name</label>
						<div class="col-sm-10">
							<input type="text" :disabled="project.syncActive" class="form-control" v-model="collection.name" >
						</div>
					</div>
					<div class="row mb-3">
						<label class="col-sm-2 col-form-label">State</label>
						<div class="col-sm-10">
							<input type="text" disabled class="form-control" v-model="collection.state" >
						</div>
					</div>
					<div class="row mb-3">
						<label class="col-sm-2 col-form-label">Source count</label>
						<div class="col-sm-10">
							<input type="text" disabled class="form-control" v-model="project.sourceCount" >
						</div>
					</div>
					<div class="row mb-3">
						<label class="col-sm-2 col-form-label">Project desc</label>
						<div class="col-sm-10">
							<textarea class="form-control" :disabled="project.syncActive" v-model="collection.description"></textarea>
						</div>
					</div>
					<div class="row mb-3">
						<label class="col-sm-2 col-form-label">Catalog description</label>
						<div class="col-sm-10">
							<textarea class="form-control" :disabled="project.syncActive" v-model="collection.publicDescription"></textarea>
						</div>
					</div>
					<div class="row mb-3">
						<label class="col-sm-2 col-form-label">Collection image - large</label>
						<div class="col-sm-10">
							<input type="file" class="form-control" :disabled="project.syncActive">
						</div>
					</div>
					<div class="row mb-3">
						<label class="col-sm-2 col-form-label">Collection image - small</label>
						<div class="col-sm-10">
							<input type="file" class="form-control" :disabled="project.syncActive">
						</div>
					</div>
					<div class="row">
						<button :disabled="project.syncActive" class="btn btn-primary">Update collection</button>
					</div>
					<div class="row mt-5">
						<router-link :to="{name: 'manager-bulk-upload', params: {collection: collection.id}}" class="btn btn-primary btn-xs">Upload data</router-link>
					</div>
				</form>
			</div>

			<div class="" v-if="this.bulks.length > 0">
				<h3>Collection uploads</h3>
				<table class="table">
				<thead>
					<tr>
						<th>Upload</th>
						<th>Size</th>
						<th>State</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="upload in bulks">
						<td>{{ upload.filename }}</td>
						<td>{{ upload.fileSize }} B</td>
						<td>
							<div class="spinner-border spinner-border-sm" role="status">
								<span class="visually-hidden"></span>
							</div>
							<span class="badge rounded-pill text-bg-success mx-2">{{ upload.state }}</span>
						</td>
						<td></td>
					</tr>
				</tbody>
				</table>
			</div>
		</div>

	</div>

</template>

<script> 
import { useProjectStore } from '../stores/project-store'
import { useCollectionStore } from '../stores/collection-store'
import BulkService from "../services/bulk-service";

export default {
	components: {},
	name: 'ProjectManager',
	props: {
		projectId: [String,Number],
		collectionId: [String,Number],
	},
	data: () => ({
		search: '',
		projects: useProjectStore(),
		collections: useCollectionStore(),
		collection: {},
		project: {},
		bulks: [],
	}),
	mounted: async function() {
		

		await this.load()
	},
	methods: {
		load: async function() {

			if(this.projects && this.projects.items && this.projects.items.length == 0) {
				await this.projects.fetch()
			}

			this.project = this.projects.items.find(f => f.id == this.projectId)

			await this.collections.get(this.projectId, this.collectionId).then( r => {
				this.collection = r.data
				console.log(this.collection)
//				this.$nextTick( this.prepAttributes );
			 })

			console.log(this.project );
			this.loadBulks();
		},
		loadBulks: function() {
			let bulkService = new BulkService();
			bulkService.fetchAll(this.projectId, this.collectionId).then( r => {
				this.bulks = r.data.data;
			} )
		}
	}
}

</script>
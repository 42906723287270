<template>
	
	<div class="container">
		<router-link :to="{name: 'projects'}" class="btn btn-primary btn-xs">&laquo; Zpět na přehled projektů</router-link>

		<div class="row align-items-stretch g-4 py-3">
			<div class="col-12">
				<div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" :style="{'background-image': 'url(\'' + project.image + '\')'}">
					<div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
						<h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold" style="text-shadow: 2px 2px 4px #000;">{{project.name}}</h3>
						<ul class="d-flex list-unstyled mt-auto">
						</ul>
					</div>
				</div>
			</div>
			<div class="col-12 mt-3" v-html="project.description">
			</div>
		</div>

		<div class="alert alert-info" v-if="collections.items.length == 0">Tento projekt nemá žádné kolekce.</div>

		<div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5" v-if="collections.items.length > 0">
			<div class="col" v-for="collection in collections.items" :key="collection.id">
				<div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" :style="{'background-image': 'url(\'' + collection.image + '\')'}">
					<div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
						<h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold" style="text-shadow: 2px 2px 4px #000;">{{collection.name}}</h3>
						<ul class="d-flex list-unstyled mt-auto">
							<li class="me-auto" style="text-shadow: 2px 2px 4px #000;">
								<i v-if="collection.availabilityId == 1" class="bi bi-lock-fill" style="font-size: 2rem"></i>
								<i v-if="collection.availabilityId == 2" class="bi bi-database-check" style="font-size: 2rem"></i>
								<i v-if="collection.availabilityId == 3" class="bi bi-bucket-fill" style="font-size: 2rem"></i>
							</li>
							<li class="d-flex align-items-center" v-if="collection.availabilityId == 2">
								<router-link :to="{name: 'collection', params: {collection: collection.id}}" class="btn btn-primary btn-xs">Detail</router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div>
			
		</div>
	</div>

</template>

<script> 
import { useProjectStore } from '../stores/project-store'
import { useCollectionStore } from '../stores/collection-store'

export default {
	components: {},
	name: 'Projects',
	props: {
		projectId: [String,Number],
	},
	data: () => ({
		search: '',
		projects: useProjectStore(),
		collections: useCollectionStore(),
		project: {}
	}),
	mounted: async function() {
		

		await this.load()
	},
	methods: {
		load: async function() {

			if(this.projects && this.projects.items && this.projects.items.length == 0) {
				await this.projects.fetch()
			}

			this.project = this.projects.items.find(f => f.id == this.projectId)

			await this.collections.fetch(this.projectId);

			console.log(this.project );
		},
	}
}

</script>
import { axiosInst } from "../main";

export default class AuthService {

    async authenticate() {
        console.log("Authenticating")
        return Promise.resolve({result: true});
    }

    async verify()  {
        const response = await axiosInst.get('/auth/verify', {
        })
        return response.data
    }
}
<template>
	
	<div class="container">
		<div class="">
			<div class="row">
				<div class="col-6">
					<input @keyup.enter="doSearch()" v-model="query.q" class="form-control form-control-lg" placeholder="Název projektu, klíčová slova"  type="text" name="q">
				</div>
				<div class="col-4">
					<select name="type" v-model="query.type"  class="form-select form-select-lg" >
						<option value="">Nezáleží</option>
						<option value="doi">Projekt s přiděleným DOI</option>
					</select>
				</div>
				<div class="col">
					<div class="d-grid gap-2">
						<a @click="doSearch()" class="btn btn-block btn-lg btn-primary">Vyhledat</a>
					</div>
				</div>
			</div>
		</div>
		<div v-if="collections.searchResults.length == 0" class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
			<div class="col" v-for="project in projects.items" :key="project.id">
				<div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-sm" :style="{'background-image': 'url(\'' + project.image + '\')'}">
					<div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
						<h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold text-shadow-1" style="text-shadow: 2px 2px 4px #000;">{{project.name}}</h3>
						<ul class="d-flex list-unstyled mt-auto">
							<li class="me-auto">
								<router-link :to="{name: 'project', params: {project: project.id}}" class="btn btn-primary btn-xs">Detail</router-link>
							</li>
						</ul>
					</div>
				</div>
      		</div>
		</div>

		<div v-if="collections.searchResults.length > 0">
			<div style="margin-top: 30px">
				<a @click="clearResults()" class="btn btn-primary btn-xs">&laquo; Zpět na přehled projektů</a>
			</div>
			<table style="margin-top: 20px" class="table table-stripesd table-hover align-middle">
			<thead>
			<tr>
				<th>Projekt</th>
				<th>Kolekce</th>
				<th>Dostupnost</th>
				<th></th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="r in collections.searchResults" :key="r.id">
				<td>
					<router-link :to="{name: 'project', params: {project: r.projectId}}">
						{{ r.project }}
					</router-link>
				</td>
				<td>
					<router-link :to="{name: 'collection', params: {collection: r.id, project: r.projectId}}">
						{{ r.name }}
					</router-link>
				</td>
				<td>
					<i v-if="r.availabilityId == 1" class="bi bi-lock-fill"></i>
					<i v-if="r.availabilityId == 2" class="bi bi-database-check"></i>
					<i v-if="r.availabilityId == 3" class="bi bi-bucket-fill"></i>
				</td>
				<td>
					<router-link :to="{name: 'collection', params: {collection: r.id, project: r.projectId}}" class="btn btn-primary btn-xs">&raquo;</router-link>
				</td>
			</tr>
			</tbody>
			</table>
		</div>
	</div>
</template>

<script> 
import { useCollectionStore } from '../stores/collection-store';
import { useProjectStore } from '../stores/project-store'

export default {
	components: {},
	name: 'ProjectsList',
	data: () => ({
		search: '',
		projects: useProjectStore(),
		collections: useCollectionStore(),
		query: {}
	}),
	mounted: function() {
		this.load()
	},
	methods: {
		load: function() {
			this.projects.fetch();
		},
		doSearch: function() {
			this.collections.search( this.query )
		},
		clearResults: function() {
			this.collections.searchResults = [];
		}
	}
}

</script>
<template>
	
	<div style="margin-top: 35px;" v-if="tree.length > 0">
		<h5 style="padding: 0; margin: 0;">Package preview</h5>

		<ul class="collection-tree">
			<li class="tree-item" v-for="treeItem in tree" :key="treeItem.name">
				<div class="item-row">
					<span class="icon carret"><i :class="getIcon(treeItem)"></i></span>
					<a @click="openChilds(treeItem)" class="name">{{ treeItem.name }}</a>
					<span class="icon-bar">
						<span><i class="bi bi-unlock"></i></span>
					</span>
				</div>
				
				<ul class="collection-tree nested" v-if="treeItem.active && treeItem.childs">
					<li class="tree-item" v-for="treeItem in treeItem.childs" :key="treeItem.name">
						<div class="item-row">
							<span class="icon carret"><i :class="getIcon(treeItem)"></i></span>
							<a v-if="treeItem.type=='dir'" @click="openChilds(treeItem)" class="name">{{ treeItem.name }} </a>
							<a v-if="treeItem.type=='file' && treeItem.direct" :href="'/assets/' + treeItem.uri" class="name">{{ treeItem.name }} </a>
							<span class="icon-bar">
								<span><i class="bi bi-lock"></i></span>
							</span>
						</div>
						<ul class="collection-tree nested" v-if="treeItem.active && treeItem.childs">
							<li class="tree-item" v-for="treeItem in treeItem.childs" :key="treeItem.name">
							<div class="item-row">
								<span class="icon carret"><i :class="getIcon(treeItem)"></i></span>
								<a target="_blank" :href="'//collector.datahive.czu.cz' + treeItem.uri" class="name">{{ treeItem.name }}</a>
								<span class="icon-bar">
									<span><i class="bi bi-unlock"></i></span>
								</span>
							</div>
							</li>
						</ul>
					</li>
				</ul>
			</li>
		</ul>

	</div>

</template>

<script> 
import { useProjectStore } from '../stores/project-store'
import { useCollectionStore } from '../stores/collection-store'
import CollectionService from '../services/collection-service'

export default {
	components: {},
	name: 'PackageView',
	props: {
		projectId: [String,Number],
		collectionId: [String,Number],
	},
	data: () => ({
		search: '',
		projects: useProjectStore(),
		collections: useCollectionStore(),
		project: {},
		collection: {},
		tree: [],
	}),
	mounted: async function() {
		await this.load()
	},
	computed: {

	},
	methods: {
		load: async function() {
			let tree = [];
			let loadCol = "";

			if(this.collectionId == 38) {
				loadCol = "test-col";
			}

			if(this.collectionId == 39) {
				loadCol = "col-amalie";
			}

			if(loadCol != "") {

				let dataset = [];
				let colMgr = new CollectionService()
				colMgr.getCollectionListing(loadCol)
				.then( r => {

					let tmpList = r.data.sort( (a, b) => a.name.toLowerCase() > b.name.toLowerCase() )

					this.tree = [];
					
					if(this.collectionId == 38) {
						this.tree.push({name: "Collection documents", icon: "", active: true, type: "dir", desc: "data", childs: [
							{name: "readme_fotopasti", uri: "readme_fotopasti.pdf" , icon: "pdf", active: false, type: "file", desc: "file", direct: true}
						]},)
					}

					this.tree.push( {name: "Collection data", icon: "", active: false, type: "dir", desc: "data", childs: tmpList} );
				} )
				
				/*
				tree = [
					{name: "Project description", icon: "", active: true, type: "dir", desc: "description", childs: [
						{name: "README", icon: "file", active: true, type: "file", desc: ""}
					]},
					{name: "Project metadata", icon: "", active: true, type: "dir", desc: "metadata", childs: [
						{name: "Metadata description", active: true, icon: "file", type: "file", desc: ""}
					]},
					{name: "Collection data", icon: "", active: false, type: "dir", desc: "data", childs: dataset}
						{name: "Sub folder",  active: false, icon: "dir", type: "dir", desc: "", childs: [
							{name: "IMG_88", icon: "jpg", type: "file", desc: "", size: "335KB"},
							{name: "IMG_2", icon: "jpg", type: "file", desc: "", size: "335KB"},
							{name: "IMG_66", icon: "jpg", type: "file", desc: "", size: "335KB"},
							{name: "IMG_69", icon: "jpg", type: "file", desc: "", size: "335KB"},
							{name: "IMG_699", icon: "jpg", type: "file", desc: "", size: "335KB"},
						]},
						{name: "Sub folder 2", active: false, icon: "dir", type: "dir", desc: "", childs: [
							{name: "IMG_A", icon: "jpg", type: "file", desc: "", size: "335KB"},
							{name: "IMG_B", icon: "jpg", type: "file", desc: "", size: "335KB"},
							{name: "IMG_C", icon: "jpg", type: "file", desc: "", size: "335KB"},
							{name: "IMG_D", icon: "jpg", type: "file", desc: "", size: "335KB"},
							{name: "IMG_E", icon: "jpg", type: "file", desc: "", size: "335KB"},
						]}
					]},
				]
				*/
				
			}

			this.tree = tree;
			
		},
		openChilds(item) {
			if(!item.active) {
				item.active = false;
			}
			this.$nextTick( () => {
				item.active = !item.active;
			} )
			console.log(item.name, item.active);
		},
		getIcon: function(item) {
			if(item.type == "dir" && !item.icon && item.icon=="") {
				return "bi bi-folder"
			}

			if(item.type == "dir") {
				switch (item.icon) {
					case "dir":
						return "bi bi-folder";
				}
			}

			if(item.type == "file") {
				switch (item.icon) {
					case "jpg":
						return "bi bi-filetype-jpg";
					case "png":
						return "bi bi-filetype-png";
					case "md":
						return "bi bi-filetype-md";
					case "doc":
						return "bi bi-filetype-doc";
					case "docx":
						return "bi bi-filetype-docx";
					case "xls":
						return "bi bi-filetype-xls";
					case "xlsx":
						return "bi bi-filetype-xlsx";
					case "json":
						return "bi bi-filetype-json";
					case "svg":
						return "bi bi-filetype-svg";
					case "txt":
						return "bi bi-filetype-txt";
					case "xml":
						return "bi bi-filetype-xml";
					case "pdf":
						return "bi bi-filetype-pdf";
					case "bin":
						return "bi bi-earmark-binary";
					default:
						return "bi bi-file";

				}
			}
		}
	}
}

</script>
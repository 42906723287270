import { defineStore } from "pinia";
import AuthService from "../services/auth";

export const authStore = defineStore("authStore", {
    state: () => ({
        uic: Number,
        displayName: String,
        firstname: String,
        lastname: String,
        initials: '--',
        upn: String,
        titleBefore: String,
        titleAfter: String,
        orcId: String,
        scopusId: String,
        loaded: false,
    }),
    getters: {
        getAvatar() {

            return "PC";
            if(!this.loaded) {
                 this.login("a", "b")
                console.log("loaded", this.upn)
            }
            return this.firstname.substring(1) + this.lastname.substring(1);
        }
    },
    actions: {
        async login(user, password) {

            let authService = new AuthService();

            const authResult = await authService.authenticate("a", "b");
            if(authResult.result) {
                var userDetails = await authService.getUser("a");
                console.log("fetching user", userDetails)
                this.$patch({
                    ...userDetails
                })
                this.loaded = true
            }
        },
        async verify() {
            if(this.loaded) {
                return;
            }

            let authService = new AuthService();

            var userDetails = await authService.verify();
            this.$patch({
                ...userDetails
            })
            this.loaded = true
        }
    }
})